import TagManager from "react-gtm-module";

export function gtag(args: any) {
  TagManager.dataLayer({ dataLayer: { ...args } });
}

export function gtagEvent(event: string, params: any) {
  TagManager.dataLayer({
    dataLayer: {
      event: `forked.${event}`,
      ...params,
    },
  });
}

// gtag('consent', 'default', {
//     'ad_storage': 'denied',
//     'analytics_storage': 'denied'
//   });
